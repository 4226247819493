.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    overflow: auto;
}
.popup-container {
    background: #1c1c1c;
    padding: 50px;
    border-radius: 12px;
    width: 614px;
    max-width: 90%;
    box-shadow: 0px 4px 10px rgba(255, 0, 0, 0.2);
    height: auto;
}
.popup-title {
    color: #fff;
    font-size: 24px;
    margin-bottom: 15px;
    text-align: start;
}
.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    label {
        color: #fff;
        font-size: 14px;
        margin-bottom: 5px;
        text-align: start;

        .required {
            color: #D22F26;
        }
    }
    select, input {
        background: #2b2b2b;
        color: #fff;
        border: 1px solid #444648;
        border-radius: 6px;
        padding: 10px;
        font-size: 14px;
        outline: none;

        &:focus {
            border-color: #D22F26;
            box-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
        }
    }
}
.input-group {
    display: flex;
    align-items: center;
    gap: 10px;

    input {
        flex: 1;
    }
}
.BtnGlobal {
    all: unset; 
    border-radius: 50px !important; 
    padding: 3px 25px; 
    display: inline-block;
    background-color: #0D0D0D;
    color: white;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border: 1px solid #D22F26;
}
.parent-container {  
    display: flex;
    justify-content: center; 
    align-items: center;
    margin-bottom: 5px;
    text-align: center;   
}
.toggle-group {
    display: flex;
    gap: 10px;

    .toggle-btn {
        flex: 1;
        padding: 8px;
        text-align: center;
        border-radius: 6px;
        cursor: pointer;
        font-weight: bold;
        border: 1px solid #D22F26;
        background: #333;
        color: #fff;
        transition: 0.3s ease;

        &.active {
            background: #D22F26;
            color: white;
        }
    }
}
.upload-btn-up {
    border: 1px solid #D22F26;
    color: white;
    padding: 8px;
    border-radius: 6px;
    cursor: pointer;
   width: 96px;
background-color: #0D0D0D;
    &:hover {
        background: #D22F26;
    }
}
.popup-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .cancel-btn {
        border: 1px solid red;
        color: white;
        padding: 10px 15px;
        border-radius: 6px;

        &:hover {
            background: red;
        }
    }
    .add-btn {
        background: #D22F26;
        color: white;
        padding: 10px 15px;
        border-radius: 6px;

        &:hover {
            background: darkred;
        }
    }
}
.form-groupSSAI {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}
.form-groupSSAI label {
    white-space: nowrap; 
    margin-right: 10px; 
    color: #fff;
}
.tabContainer {
    display: flex;
    border: 1px solid #292727;
    border-radius: 50px;
    overflow: hidden;
    width: fit-content;
}
.tabContainer button {
    background: transparent;
    border: none;
    padding: 2px 30px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background 0.3s, color 0.3s;
}
.tabContainer button:not(.active) {
    color: #fff;
}
.subtext{color: #fff;
    font-size: 14px;
    margin-bottom: 5px;
    text-align: start;
}
.form-groupOther{
    display: flex;
    flex-direction: row; 
    align-items: center; 
    gap: 10px;
    margin-bottom: 15px;
}
.upload-name {
    display: flex;
    flex-direction: row; 
    align-items: center; 
    gap: 10px;
    margin-bottom: 15px;
}
.form-groupOther-up{
    align-items: center; 
    gap: 10px;
    margin-bottom: 15px;
}
.formLabel{
    color: #fff;
}
.media-upload-section-distribution {
    display: flex;
    gap: 16px; 
    margin-top: 12px;
    margin-bottom: 17px;
}

.upload-box-distribution {
    width: 108px; 
    height: 88px; 
    border: 0.5px dashed #ff0000; 
    border-radius: 8px; 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    padding: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
   
}

.upload-placeholder-distribution {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF14;
    padding: 5px;
    border-radius: 6px;
    width: 108px; 
    height: 78px; 
}

.upload-icon-distribution {
    font-size: 24px;
    color: #ff0000;
    margin-bottom: 8px;
}

.upload-label-distribution {
    font-size: 12px;
    color: #D9D9D9;
    line-height: 1.4;
}

.tabContainer-distribution .active {
    background-color: #000;
    color: #fff;
    border-radius: 16px;
    padding: 4px 12px;
}

.tabContainer-distribution button {
    margin-right: 8px;
    border: none;
    cursor: pointer;
}
.formLabel-up {
    margin-bottom: 10px;
    display: block;
    color: #fff;
}
.required-up {
    color: red;
}

.upload-container-up {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    flex-direction: row;
}
.upload-section-up {
    border: 2px dashed red;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.upload-box-up {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.file-input-up {
    display: none;
}
.upload-label-up {
    margin-top: 10px;
    font-weight: bold;
}
.upload-size-up {
    font-size: 12px;
    color: gray;
}