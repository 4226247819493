@import '../../../assets/css/variables.scss'; 
.content-body{
  .deshboard-content {
      padding: 20px 15px;
      .title {
          h2 {
              font-weight: 600;
              font-size: 32px;
              margin-bottom: 20px;
          }
      }
  }
  &.dark{
      .title {
          h2 {
              color: $dark_heading_text;
          }
      }

      @media (max-width: 768px) {
      .view-duration {
        display: flex; 
        flex-wrap: wrap; 
        gap: 20px; 
 
        max-width: 1200px; 
       
  
          .view-block {
            flex: 1 1 100%; 
      max-width: 100%
              h5 {
                  color: $dark_heading_text;
              }
              .radio-list {
                  .radio-box {
                      input[type='radio'] {
                          accent-color: #fff;
                      }
                      label {
                          background: #0D0D0D;
                          color: #999999;
                          font-size:15px;
                      }
                      input[type="radio"]:checked + label {
                          background-color: #D22F26;
                          color: #ffffff;
                          font-size:15px;
                          img {
                              filter: brightness(0) saturate(100%) invert(100%) sepia(45%) saturate(14%) hue-rotate(290deg) brightness(114%) contrast(91%);
                          }
                      }
                  }
              }
              
          }
          .duration-block {
            flex: 1 1 100%;
      max-width: 100%
              h5 {
                  color: $dark_heading_text;
              }
              .radio-list {
                  .radio-box {
                      label {
                          background: #0D0D0D;
                          color: #999999;
                          font-size:15px;
                      }
                      input[type="radio"]:checked + label {
                          background-color: #D22F26;
                          color: #ffffff;
                          font-size:15px;
                          img {
                              filter: brightness(0) saturate(100%) invert(100%) sepia(45%) saturate(14%) hue-rotate(290deg) brightness(114%) contrast(91%);
                          }
                      }
                  }
              }
          }
      }}
      .channel-container {
          .channel-table {
              .channel-table-header {
                  background:#444649 ;
                  color: #FFFFFF;
                  font-size: 12px;
                  .table-row {
                      .table-header-col {
                        display: flex;
                      }
                  }
              }
              .channel-table-body {
                  .channel-accordion {
                      .accordion-block {
                          .toggle {
                              background: $dark_card_body;
                              .table-row {
                                  .table-col {
                                      color: $dark_heading_text;
                                    //   padding-left: 40px;
                                  }
                              }
                             
                          }
                          .inner {
                              background: $dark_accordation_background_body;
                              .table-content {
                                  .table-row {
                                      .table-col {
                                          color: $dark_heading_text;
  
                                      }
                                  }
                              }
                              .pagination {
                                  background: $dark_card_background;
                                  .items-per-page {
                                      label {
                                          color: $dark_heading_text;
                                      }
                                  }
                              } 
                          }
                      }
                  }
              }
          }
          .active-user-section {
              .active-user-block {
                  background: $dark_map_card_body;
                  height: '100%';
                  .top {
                      .left-side {
                          h4, p {
                              color: $dark_heading_text;
                          }
                      }
                  }
                  .top-state {
                      h6 {
                          color: $dark_heading_text;
                      }
                      ul {
                          li {
                              span {
                                  color: $dark_heading_text;
                              }
                          }
                      }
                  }
              }
          }
      }
  }
}

.view-block { 
  .radio-list {
      display: flex;
      align-items: center;
      .radio-box {
          margin-right: 20px;
          input[type="radio"] {
              opacity: 0;
              position: absolute;
          }
          label {
              background: #FFFFFF;
              box-shadow: 2px 0px 24px rgba(153, 153, 153, 0.16);
              border-radius: 6px;
              padding: 3px 8px;
              color: #999999;
              font-size: 16px;
              position: relative;
              margin-left: 25px;
              cursor: pointer;
              img {
                  margin-right: 7px;
              }
              &::before {
                  content: "";
                  display: inline-block;
                  border: 2px solid #D9D9D9; 
                  width: 18px;
                  height: 18px ;
                  display: inline-block;
                  border-radius: 50%;
                  position: absolute;
                  left: -25px;
                  top: 6px;
              }
          }
          input[type="radio"]:checked + label::after {
              content: "";
              display: inline-block;
              background-color:  #D9D9D9; 
              width: 8px;
              height: 8px;
              display: inline-block;
              border-radius: 50%;
              position: absolute;
              left: -20px;
              top: 11px;
          } 
          input[type="radio"]:checked + label {
              background-color: #D22F26;
              color: #ffffff;
              img {
                  filter: brightness(0) saturate(100%) invert(100%) sepia(45%) saturate(14%) hue-rotate(290deg) brightness(114%) contrast(91%);
              }
          }
      }
  }
}

.channel-container {
  width: 100%;
  display: flex; 
  margin-top: 40px;
  margin-bottom: 20px;

  .channel-table{
      width: 70%;
  }
  .active-user-section {
      width: 30%;
      padding-left: 20px;
  }
}

.channel-table-header {
  background: #FFFFFF;
  box-shadow: 0px 1px 12px rgba(16, 16, 16, 0.06);
  border-radius: 6px 6px 0px 0px;
  margin-bottom: 10px;
  .table-row {
      display: flex; 
      .table-header-col {
          color: #999999;
          padding: 16px 10px;
          text-align: center;
          flex-basis: 200px;
          font-size: 13px;
          @media screen and (max-width:1800px) {
              flex-basis:150px;
          }
          &.name {
              text-align: left;
              flex-grow: 3;
          }
          small {
              display: block;
              font-size: 12px;
          }
      }
  }
}

.channel-table-body {
  max-height:700px;
  overflow: auto;
       &:-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
              background-color: #F5F5F5;
          }

      &::-webkit-scrollbar {
          width: 5px;
          background-color: #FFFFFF14;
      }

      &::-webkit-scrollbar-thumb {
          background-color: #D22F26;
          border: 2px solid #D22F26;
          border-radius: 50px;
      }

 
}
.inner {
  // display: none;
  background-color: #ffffff;
  padding:10px 40px;
  box-shadow: 0px 10px 12px rgba(16, 16, 16, 0.16);
  border-radius: 0 0 6px 6px;
  .info {
      font-size: 12px;
      line-height: 100%;
      background: #EFEFEF;
      border-radius: 19px;
      color: #333333;
      padding: 4px 16px;
      display: inline-block;
      width: 100%;
  }
}
.table-content {
  padding: 20px 0;
  max-height: 360px;
  overflow: auto;
  &:-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
  }

  &::-webkit-scrollbar {
      width: 5px;
      background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
      background-color: #404040;
      border: 2px solid #555555;
  }
  .table-row {
      border-bottom: 0.5px solid #CCCCCC;
      .table-col {
          padding: 12px 10px;
          word-wrap: break-word;
          &.name {
              // min-width: 240px;
              padding-left: 0px;
          }

          &:last-child {
              padding-right: 0px;
              min-width: 160px;
              max-width: 160px;
              padding-left: 40px;
              @media screen and (max-width:1800px) {
                  min-width: 100px;    
                  max-width: 100px;
              }
          }
      }
      &:last-child {
          border-bottom: none;
      }
  }

  
}
.BG-TABLE{
    background-color: #2E3033;
    padding: 10px;
    border-radius: 10px;

}