.channel-setup-header {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 30px;

    .channel-setup-search {
        flex-grow: 3;
        
        
    }
}
.cms-table-list {
    .single-row{
        &:hover {
            background: linear-gradient(#f7f7f7, #f7f7f7) padding-box,
              linear-gradient(to right, #22bbbe, #7dad41, #f4b719, #e55824, #b52c80)
                border-box;
            border-radius: 6px;
            border: 1px solid transparent;
            box-shadow: 14px 14px 12px 1px rgba(14, 24, 39, 0.16);
            transform: translateY(-4px);
          }
        
          &.dark {
            // background: $dark_card_background; 
        
            &:hover {
              background: linear-gradient(#2E3033, #3e3b3b) padding-box,
              linear-gradient(to right, #22bbbe, #7dad41, #f4b719, #e55824, #b52c80)
                border-box;
              border-radius: 6px;
              border: 1px solid transparent;
              box-shadow: 14px 14px 12px rgba(0, 0, 0, 0.5);
              transform: translateY(4px);
            }
          }
    }
    
    .table-action-group {
       
        .action-label {
            min-width: 80px;
        }
    }

    .col1 {
        width:25%;
    }

    .col2 {
        width: 20%;
    }

    .col3 {
        width: 15%;
    }

    .col4 {
        width: 15%;
    }

    .col5 {
        width: 15%;
    }
    .col6 {
        width: 10%;
    }
}

.table-pagination .pagination-count .pagination-arrow {
    width: 120px;
    input {
        text-align: center;
        border-radius: 4px;
        border: 1px solid #e6e6e6;
        width: 50px;
    }
}

.dark-theme {
    .pagination-arrow {
        input {
            background: #2e3033 !important;
            color: #ffffff !important;
        }
    }
}

.tableLoader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.switch-loader {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
    position: absolute;
    left: 50%;
    z-index: 1000;
    background: rgba(0,0,0,0.4);
    margin-left: -12px;
}
.switch-loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 3px solid #fff;
    animation: prixClipFix 2s linear infinite ;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
}

@keyframes rotate {
    100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}
.left-side-search{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}
.distribution-header{
    margin-top: 25px;
}
.dashboard-title{
    font-size: 25px;
    color: #999999;
}