.channel-distrubution-button {
    display: flex;
    gap: 10px;
  }
  
  .channel-Distribution {
    display: flex;
    justify-content: space-between;
  }
  
  .btn-Distribution {
    background-color: #00000042;
    color: #999999;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid #808080;
    position: relative;
    overflow: hidden;
   
  }
  
  .btn-red {
    background: #D22F26;
    color: white;
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
  }
  
  .btn-red:hover {
    background: #D22F26;
  }
  
  .btn-add {
    background: #00000042;
    color: white;
    padding: 9px 23px;
    border-radius: 8px;
    border: 1px solid #D22F26;
    font-size: 16px;
  }
  
  .black-screen {
    width: 100%;
    height: 100vh;
    margin-top: 20px;
  }
  .content{
    margin-bottom: 20px;
  }
  .dropdown-container {
    position: relative;
    background-color: #2E3033;
    margin-right: 17px;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    background: white;
    border: 1px solid #ccc;
    padding: 8px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
   font-family: 'Roboto', sans-serif;
  
  }
  
  .dropdown-menu button {
    background: none;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    text-align: left;
    width: 100%;
    font-size: 16px;
    color: #999999;
  }
  
  .dropdown-menu button:hover {
    background: #D22F26;
  }
  .dropdown-item {
    background-color: #2E3033;
    color: white;
    border: none;
    padding: 10px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    font-size: 16px;
  }
  
  .dropdown-item:hover,
  .dropdown-item.active {
    background-color: #D22F26;
    font-size: 16px;
    font-weight: 600;
  }
  
  .dropdown-item.active {
    background-color: #D22F26;
    border-radius: 6px;
  }  
  