div[role="gridcell"] {
  display: inline-block;
  line-height: 1.5;
  overflow-wrap: break-word;
  white-space: normal;
  text-align: justify;
}

.partnerDatepicker {
  width: 100px;
}

.textEditorClassname {
  appearance: none;

  box-sizing: border-box;
  inline-size: 100%;
  block-size: 100%;
  padding-block: 0;
  padding-inline: 6px;
  border: 2px solid #ccc;
  vertical-align: top;
  color: var(--rdg-color);
  background-color: var(--rdg-background-color);

  font-family: inherit;
  font-size: var(--rdg-font-size);

  &:focus {
    border-color: var(--rdg-selection-color);
    outline: none;
  }

  &::placeholder {
    color: #999;
    opacity: 1;
  }
}

.editableMulti {
  overflow: visible;

  :global .optionListContainer {
    z-index: 55;
  }
}

.multiDropCell,
.rdg-cell {
  overflow: visible;
}

.bulkGrid {
  margin-top: 30px;
  width: 100%;


  display: flex;
  flex-direction: column;

 
  .scrollTop {
    width: 100%;
    overflow-x: auto;
    position: sticky;
    top: 0;
    height: 12px;

    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888; 
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
  }

  
  .scrollContent {
    flex: 1; 
    width: 100%;
    overflow: auto; 

    &::-webkit-scrollbar {
      width: 5px; 
      height: 5px; 
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  }

 
  .tableContainer {
    width: 200%; 
  }
}


.timepicker {
  padding: 0.375rem;
  font-size: 0.8rem;
}

.gridDropdown {
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 22px;
  padding: 5px;
}

[role="columnheader"] {
  background-color: #ff2b05;
  color: #fff;
}

[role="row"] {
  transition: all 0.4s;
}

[role="gridcell"] select {
  width: 120px;
}

.backicon {
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  line-height: 36px;
  vertical-align: middle;
  display: inline-flex;
  text-align: justify;
  border-radius: 6px;
  border: 0.5px solid #E6E6E6;
  background: transparent;
  cursor: pointer;

  :global {
    img {
      width: 12px;
      height: 12px;
    }
  }
}

.backicontitle {
  margin-left: 16px;
  line-height: 34px;
  display: inline-block;
}

.customReadonlyLabel {
  padding: 0px 8px;
  word-break: break-all;
  display: inline-block;

  :global input {
    width: 96px;
  }
}

.customGridInput {
  appearance: none;
  box-sizing: border-box;
  inline-size: 100%;
  block-size: 100%;
  padding-block: 0;
  padding-inline: 6px;
  border: 2px solid #ccc;
  vertical-align: top;
  color: var(--rdg-color);
  background-color: var(--rdg-background-color);
  font-family: inherit;
  font-size: var(--rdg-font-size);


  .multiselectContainer {
    position: relative;
    width: 100%;
    min-width: 150px;
    
    overflow: visible;
 
  }

  .multiselectContainer .multiselect-dropdown {
    z-index: 999;

  }
}
