@import "../../assets/css/mixins.scss";

.search-bar {
    width: 324px;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid #E6E6E6;
   
    @include border-radius(6px);

    input {
        height: 42px;
        padding: 7px 24px;
        background: none;
        border: none;
        outline: none;
        color: #999999;
        font-size: 14px;
        flex-grow: 3;
        

        &::placeholder {
            color: #999999;
        }
    }

    button.search-btn {
        border: none;
        outline: none;
        background: none;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0px;
        padding: 0px 24px 0px 10px;
    }
}