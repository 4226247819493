$background-color: #1e1e1e;
$header-color: #999999;
$text-color: #ffffff;
$input-bg: #2a2a2a;
$border-color: #3a3a3a;
$primary-color: #ff4d4d;

.distributionPlatform {
  padding: 20px;
  border-radius: 10px;
  color: $text-color;
  max-width: 100%;
  margin: auto;
  min-height: 100vh;

  .title {
    font-size: 20px;
    font-weight: 500;
    color: $header-color;
    margin-bottom: 20px;
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 50px;
  }

  .formGroup {
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    label {
        font-size: 14px;
        font-weight: 600;
    //   margin-bottom: 5px;
      color: $header-color;
    }
    .required {
        color: red;
      }

    input {
      background: $input-bg;
      border: 1px solid $border-color;
      padding: 8px;
      border-radius: 5px;
      color: $text-color;
      outline: none;
    }
  }

  .toggleSwitch {
    position: relative;
    width: 40px;
    height: 20px;
    
    input {
      display: none;
    }

    .toggleLabel {
      position: absolute;
      width: 100%;
      height: 100%;
      background: $border-color;
      border-radius: 20px;
      cursor: pointer;
      transition: 0.3s;
    }

    input:checked + .toggleLabel {
      background: $primary-color;
    }
  }

  .radioGroup {
    display: flex;
    gap: 10px;

    label {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 5px 10px;
      border: 1px solid $border-color;
      border-radius: 5px;
      cursor: pointer;

      &.active {
        background: $primary-color;
        color: $text-color;
      }

      input {
        display: none;
      }
    }
  }

  .buttonGroup {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 200px;
 

    button {
      padding: 8px 16px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      font-weight: 500;
    }

    .cancelBtn {
      background: transparent;
      color: $header-color;
      border: 1px solid #D22F26;
      width: 97px;
      height: 44px;
    }

    .resetBtn {
      background: #FAEAE9;
      color: $primary-color;
      border: 1px solid #FAEAE9;
      width: 89px;
      height: 44px;
    }

    .updateBtn {
      background: $primary-color;
      color: $text-color;
      width: 100px;
      height: 44px;
    }
    .twoButton{
        gap: 10px;
        display: flex; 
    }
  }
}
.formRow {
    display: flex;
    gap: 140px;
    align-items: center;
  
    .formGroup {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  
    .apiKeyGroup {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .customSelect__control {
    background: $input-bg !important;
    border: 1px solid $border-color !important;
    color: $text-color !important;
  }
  
  .customSelect__menu {
    background: $input-bg !important;
    border: 1px solid $border-color !important;
  }
  
  .customSelect__option {
    background: $input-bg !important;
    color: $text-color !important;
  }
  
  .customSelect__option:hover {
    background: $primary-color !important;
  }

.multiSelect {
background-color: #2E3033;
   
  }
  .tabContainer {
    display: flex;
    background-color: rgba(255, 255, 255, 0.08); 
    border-radius: 40px;
    padding: 5px;
    width: 240px;
    // height: 45px;
    justify-content: space-between;

  
    button {
      flex: 1;
      border: none;
      background: transparent;
      color: #999;
      font-size: 16px;
      font-weight: bold;
      border-radius: 30px;
      cursor: pointer;
    
  
      &.active {
        background: black;
        color: white;
        border: 2px solid red;
      }
    }
  }
  .channelList{
    justify-content: space-between;
    display: flex;
    margin-top: 30px;

    .channelLable{
        font-size: 16px;
    }
    
  }
  .addChannelBtn{
    background: transparent;
  color: $header-color;
  border: 1px solid #D22F26;
  width: 110px;
  height: 40px;
  border-radius: 8px;
}
.channelTable {
    width: 100%;
    background-color: #393939;
    margin-top: 10px;

    thead {
        background-color: #2A2A2A;
        
        th {
            color: #A0A0A0;
            padding: 12px;
            text-align: left;
            font-size: 14px;
            font-weight: 500;
            border-bottom: 1px solid #393939;
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid #393939;

            &:last-child {
                border-bottom: none;
            }

            td {
                padding: 10px;
                font-size: 14px;
                color: #FFF;
                background-color: #252525;
            }

            td:first-child {
                text-align: center;
                font-weight: 500;
            }

            // Dropdown Styling
            select {
                width: 100%;
                background-color: #333;
                border: 1px solid #444;
                color: white;
                padding: 8px;
                border-radius: 5px;
            }

            // Input Fields
            input {
                width: 100%;
                background-color: #333;
                border: 1px solid #555;
                color: white;
                padding: 8px;
                border-radius: 5px;
            }

            // Upload Button
            .upload {
                color: #E74C3C;
                font-weight: 500;
                cursor: pointer;
            }
        }
    }
}
.editBtn{
    background-color: transparent;
}
.actionBtn{
    display: flex;
    flex-direction: row; 
    gap: 10px; 
    align-items: center;
  
}
.row-direction{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;

}
.radioApiOption{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;

  .formLabel {
    font-size: 14px;
    font-weight: 500;
    color: #333;

    .required {
      color: red;
    }
  }

  .toggleSwitch {
    position: relative;
    width: 52px;
    height: 28px;
    background: #ccc;
    border-radius: 24px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;

    input {
      display: none;

      &:checked + label {
        background: #4FC17E;

        &::after {
          transform: translateX(24px);
          content: "✔";  
          font-size: 14px;
          color: #4FC17E;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    label {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      position: relative;
      transition: background 0.3s ease-in-out;

      &::after {
        content: "";
        width: 24px;
        height: 24px;
        background: white;
        border-radius: 50%;
        position: absolute;
        left: 2px;
        top: 2px;
        transition: transform 0.3s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.flexOption{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}
.multiSelectOption{
  background-color: #333;
}
.radioOption {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  background-color: #252525;

  
}

input[type="radio"] {
  width: 15px;  
  height: 15px;
  accent-color: #E9E9E9; 
  transform: scale(1.5);  
  cursor: pointer;

  
}
