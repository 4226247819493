$bg-dark: #1e1e1e;
$bg-light: #2c2c2c;
$text-color: #ffffff;
$border-color: #444;
$primary: #007bff;
$danger: #ff4d4d;
$header-bg: #252525;

.distributionContainer {
  color: $text-color;
  border-radius: 10px;
}

.tableHeader {
  display: flex;
  align-items: center;
  background-color: #2D2D2D;
  color: white;
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #606264;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
}
.tableHeader span {
  padding: 8px 16px;
  text-align: center;
}
.partner-name {
  flex: 1; 
  text-align: center;
}
.partner-code {
  flex: 0.4;
  text-align: center;
  margin-left: 10px;
}
.api-key {
  flex: 2.5; 
  text-align: center;
  margin-left: 20px;
}
.divider {
  width: 1px;
  height: 50px;
  background-color:#606264;
  margin: 0 15px; 
}

.tableHeader span {
  text-align: start;
}

.tableWrapper {
  background: $bg-light;
  padding: 10px 10px;
  border-radius: 8px;
  margin-top: 10px;

}

.parentRow {
  background:#0D0D0D;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  cursor: pointer;
  color: $text-color;
}

.rowContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  color: $text-color;
}

.childTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 5px;
  color: $text-color;

  th {
    background: #393939;
    padding: 10px;
    color: $text-color;
  }

  td {
    padding: 10px;
    border-bottom: 1px solid $border-color;
    color: $text-color;
  }
}

.actions {
  display: flex;
  gap: 10px;
}

.icons {
  display: flex;
  gap: 10px;
  font-size: 16px;

  .icon {
    cursor: pointer;
    color: $text-color;
  }

  .delete {
    color: $danger;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  gap: 5px;

  button {
    background: #444649;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    border: none;

    &.active {
      background: darken($primary, 10%);
    }
  }
}
.urlInput{
  width: 377px;
  background-color: #FFFFFF14;
  color: #fff;
  border: 1px solid #444648;
  padding: 5px;
  border-radius: 5px;
}
.urlInputchild{
  background-color: #1A1A1A;
  color: #fff;
  border: 1px solid #444648;
  padding: 5px;
  border-radius: 5px;
  width: 217px;
}
.icons {
  display: flex;
  gap: 8px; 
  align-items: center; 
}

.icon-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px; 
}

.icon-distribution {
  display: block;
  width: 20px; 
  height: 20px;
}
